import { User } from '@api/gql/graphql'

declare global {
  interface Window {
    OlvyUtils: {
      setUser: (domain: string, user: { name: string, email: string }) => void
      showWidget: (domain: string, id: string) => void
    }
  }
}

const sleep = (ms: number) => new Promise((resolve) => {
  const t = setTimeout(() => {
    clearTimeout(t)
    resolve(null)
  }, ms)
})

export const setUser = async (user: User) => {
  while (true) {
    if (window.OlvyUtils?.setUser !== undefined) {
      window.OlvyUtils.setUser('easyphotobook', {
        name: user.name ?? '',
        email: user.email ?? '',
      })

      break
    }

    await sleep(1000)
  }
}

export const open = () => {
  window.OlvyUtils.showWidget('easyphotobook', 'bold_hofstadter_yyPCK')
}

export const feedback = {
  setUser,
  open,
}
