import { useEffect } from 'react'
import { PageName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'

export const FaqPage = () => {
  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.Faq,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.Faq,
    })

    facebookPixel.event('PageView')
  }, [])

  return (
    <div className="text-xs lg:text-base page">
      <h1 className="text-xl lg:text-5xl font-bold mb-2 lg:mb-5">FAQ</h1>

      <section className="mb-4">
        <h2 className="text-base mb-2">How fast can you ship?</h2>
        <p>
          Usually it takes up to 5 working days to manufacture a photo book.
          Shipping to the US can take about 5-7 business days outside of peak
          seasons. The rest of the world may take up to 10 days or more. Please
          be advised that these times are all estimates.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">Do you print my book?</h2>
        <p>
          Book manufacturing and payment processing is provided by our
          print-on-demand partner (Peecho).
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">
          There is an issue with my photo book. What can I do?
        </h2>
        <p>
          Sometimes an order arrives defected. In that case, we will fix it with
          a reprint free of charge. Please, send all the details to
          {' '}
          <a href="mailto:support@easyphotobook.me" className="underline text-primary">
            support@easyphotobook.me
          </a>
          and will be happy to help you. Since a photo
          book is being printed only because a specific customer ordered it, we
          are not able to sell it to another customer. Therefore, we cannot
          issue a refund.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">
          I don’t like some photos in the book I received. Can you remove them
          and reprint it?
        </h2>
        <p>
          During the book generation process a customer can delete photos that
          he/she/they doesn’t like. By clicking the Order photo book button, you
          give your consent that you previewed your book and want to print it.
          Therefore, reprint would cost you a full price. You can do it by
          creating a new order on
          {' '}
          <a href="/create" className="underline text-primary">
            create
          </a>
          .
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">How can I delete my profile?</h2>
        <p>
          To delete your Easy Photo Book profile:

          <ol className="list-decimal list-inside">
            <li>
              Click your profile picture in the top right of Easy Photo Book.
            </li>
            <li>Click My Profile.</li>
            <li>Click Delete account, then confirm it in a pop-up window.</li>
          </ol>
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">What is the status of my order?</h2>
        <p>
          To check the status of your orders:
          <ol className="list-decimal list-inside">
            <li>Click your profile picture in the top right of Easy Photo Book.</li>
            <li>Then click My Orders.</li>
          </ol>
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">What photo book sizes are available?</h2>
        <p>
          Easy Photo Book is available as 8.3x8.3 inches hardcover photo book with glossy pages.
        </p>
      </section>
    </div>
  )
}

export default FaqPage
