import { Link } from 'react-router-dom'
import { PRIVACY_LINK } from '@constants/link'

const year = new Date().getFullYear()

export const Footer = () => {
  return (
    <div className="text-xs lg:text-base flex flex-col gap-y-6 lg:gap-y-0 lg:flex-row justify-between py-4 text-white px-4 lg:px-0">
      <div className="order-2 lg:order-1 text-center lg:text-left">
        @ 2021 -
        {' '}
        {year}
        {' '}
        EasyPhotoBook. All right reserved
      </div>
      <div className="order-1 lg:order-2 flex flex-col lg:flex-row gap-y-2 lg:gap-y-0 gap-x-8 items-center lg:items-start">
        <a className="text-white hover:underline" href={PRIVACY_LINK} target="_blank" rel="noreferrer">Privacy policy</a>
        <Link className="text-white hover:underline" to="/terms-and-conditions">Terms & conditions</Link>
        <Link className="text-white hover:underline" to="/faq">FAQ</Link>
        <Link className="text-white hover:underline" to="/contacts">Contact us</Link>
      </div>
    </div>
  )
}
