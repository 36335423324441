import { useEffect } from 'react'
import { PageName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'

export const TermsAndConditionsPage = () => {
  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.TermsAndConditions,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.TermsAndConditions,
    })

    facebookPixel.event('PageView')
  }, [])

  return (
    <div className="text-xs lg:text-base page">
      <h1 className="text-xl lg:text-5xl font-bold mb-2 lg:mb-5">
        Terms & Conditions
      </h1>

      <section className="mb-4">
        <h2 className="text-base mb-2">1. Subject Matter of the Contract</h2>
        <p>
          1.1. Easy Photo Book, based in the Netherlands, operates the website
          {' '}
          <a className="underline text-primary" href="https://easyphotobook.me">easyphotobook.me</a>
          {' '}
          (the «Website»), offering users a platform to create
          and print personalized photo books. By using the Website or related
          services (the «Serviceэ»), you agree to these Terms and Conditions.
        </p>
        <p>
          1.2. These Terms and Conditions, along with our Privacy Policy, govern
          your use of the Website. Please review them carefully before using the
          Service.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">2. Use of the Service</h2>
        <p>
          2.1. To use Easy Photo Book, you must authorize the service to access
          your content, such as images, to create your photo book. By uploading
          content, you represent and warrant that you own the content or have
          obtained permission from the owner for its use. The content must not
          include personal data about individuals other than yourself, unless
          you have obtained their explicit consent.
        </p>
        <p>
          2.2. The use of Easy Photo Book’s web service is free; however,
          charges apply if you choose to purchase printed copies of your photo
          book. Payments are processed through Stripe.
        </p>
        <p>
          2.3. You may not use or upload content that you do not own or have the
          rights to. Copyright violations are taken seriously, and we reserve
          the right to remove content or terminate accounts in response to
          infringement claims. If your account is terminated due to copyright
          violations, no refunds will be issued.
        </p>
        <p>
          2.4. By using the Service, you agree that your content will be stored
          on our servers and shared only with our printing partner to produce
          your photo book. User photos may also be used to improve the quality
          of the service.
        </p>
        <p>
          2.5. You retain ownership of the content you upload, but you grant
          Easy Photo Book the right to use it for the sole purpose of providing
          the Service.
        </p>
        <p>
          2.6. Easy Photo Book reserves the right to limit or discontinue any
          part of the Service at any time without prior notice.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">3. Creation of Your Photo book</h2>
        <p>
          3.1. You can create a photo book using content from your devices,
          social media accounts, or other sources. The layout of the book will
          be generated automatically, but you are responsible for the content
          selected.
        </p>
        <p>
          3.2. You are fully responsible for ensuring that the content you
          include is lawful and does not violate the rights of any third
          parties. You agree to indemnify Easy Photo Book from any claims
          arising from the use of your content (see Section 7).
        </p>
        <p>
          3.3. Book manufacturing is managed by our print-on-demand partners. We
          reserve the right to place identifying marks, such as a logo or unique
          number, on the book cover.
        </p>
        <p>
          3.4. Once your photo book is created, you can order a printed copy for
          personal or commercial use. The book will be printed, bound, and
          shipped to the address you provide.
        </p>
        <p>
          3.5. Users are permitted to distribute their printed photo books
          commercially without restriction.
        </p>
        <p>
          3.6. Printed copies of your photo book are subject to additional
          charges. Prices and shipping costs will be clearly stated at the time
          of purchase, and we reserve the right to adjust prices without notice.
        </p>
        <p>
          3.7. After completing an order, you will receive a confirmation email.
          The contract is only valid once you receive this confirmation. We
          reserve the right to reject orders at our discretion.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">4. Payment and Refunds</h2>
        <p>
          4.1. Payments for printed photo books must be made at the time of
          order, through Stripe. The payment amount includes all applicable
          taxes and fees.
        </p>
        <p>
          4.2. Once an order has been placed, it cannot be canceled or modified.
          No refunds will be provided after the order is confirmed, except in
          cases of printing errors.
        </p>
        <p>
          4.3. In the event of a printing error, Easy Photo Book will reprint
          your order free of charge, provided you submit sufficient
          documentation of the issue. Shipping delays do not qualify for
          reprints.
        </p>
        <p>
          4.4. Delivery times are estimates and may vary, especially during peak
          periods. We do not guarantee specific delivery dates, and delays do
          not entitle you to refunds or reprints.
        </p>
        <p>
          4.5. You are responsible for providing accurate shipping information.
          If an order is returned to Easy Photo Book due to errors in the
          shipping address (e.g., typos or missing details), reshipping costs
          will be covered by the user.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">5. Rights to User Content</h2>
        <p>
          5.1. By using Easy Photo Book, you grant us a non-exclusive, worldwide
          license to process, format, and display your content solely for the
          purpose of providing the Service. This includes reproducing and
          transmitting the content to our printing partners.
        </p>
        <p>
          5.2.You can revoke these rights at any time by discontinuing use of
          the Service. However, any content already processed for orders cannot
          be withdrawn.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">6. User Responsibilities</h2>
        <p>
          6.1. You agree to provide accurate and complete information when using
          the Service, and to keep this information up-to-date.
        </p>
        <p>
          6.2 You are responsible for ensuring that your login credentials
          remain secure and are not shared with others.
        </p>
        <p>
          6.3 You agree not to misuse the Service, including attempting to
          interfere with its functionality or uploading harmful software.
        </p>
        <p>
          6.4. You may not duplicate, sell, or commercially exploit any portion
          of the Service without prior written permission from Easy Photo Book,
          except in relation to the sale or distribution of your own printed
          photo books.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">7. Liability and Indemnification</h2>
        <p>
          7.1. You are solely responsible for the content you upload and any
          consequences arising from its use. You agree to indemnify Easy Photo
          Book from any claims or liabilities resulting from content you upload,
          share, or distribute through the Service.
        </p>
        <p>
          7.2 Easy Photo Book reserves the right to review, block, or remove
          content that violates these Terms or applicable law, without prior
          notice.
        </p>
        <p>
          7.3 Although We do not generally review or monitor the Contents of
          users, We reserve the right – in our sole discretion and without
          providing the reasons – to refuse (where appropriate, partially
          refuse) and to block or delete with immediate effect, Contents which
          are accessible over our servers, in particular insofar as third
          parties make us aware of Contents which contravene these Terms of Use.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">
          8. Dispute Resolution and Governing Law
        </h2>
        <p>
          8.1. These Terms are governed by the laws of the Netherlands. Any
          disputes arising from these Terms or the use of the Service will be
          subject to the exclusive jurisdiction of the Dutch courts.
        </p>
        <p>
          8.2. We encourage resolving any disputes amicably, but if necessary,
          disputes will be settled through mediation or legal proceedings in the
          Netherlands.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">9. Modifications to the Terms</h2>
        <p>
          9.1. Easy Photo Book reserves the right to modify these Terms and
          Conditions at any time. Changes will take effect prospectively, and
          you will be notified via email or a website notice of any significant
          updates.
        </p>
        <p>
          9.2. Continued use of the Service after any modifications constitutes
          acceptance of the updated Terms.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="text-base mb-2">10. Contact Information</h2>
        <p>
          If you have any questions about these Terms, please contact us at
          {' '}
          <a
            href="mailto:privacy@easyphotobook.me"
            className="text-primary underline"
          >
            privacy@easyphotobook.me
          </a>
        </p>
      </section>
    </div>
  )
}

export default TermsAndConditionsPage
