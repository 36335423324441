import {
  FC, useEffect, useMemo, useState,
} from 'react'
import { OAuthType } from '@api/gql/graphql'
import { SvgFacebook } from '@components/icons'
import { ModalName } from '@constants/analytic'
import { COOKIE_POLICY, getLoginUrl, PRIVACY_LINK } from '@constants/link'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { cookieAgree } from '@helpers/cookie-agree'
import { Button } from '@nextui-org/button'
import { Link } from '@nextui-org/link'
import {
  Modal, ModalBody, ModalContent, ModalHeader,
} from '@nextui-org/modal'
import { useIsMobile } from '@nextui-org/use-is-mobile'

import { LoginModalProps, ModalProps } from '../_types'

export const LoginModal: FC<ModalProps & LoginModalProps> = (props) => {
  const isMobile = useIsMobile()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.ModalView,
      name: ModalName.Login,
    })
  }, [])

  const onLogin = () => {
    amplitude.event({
      type: AmplitudeEvent.LoginClick,
      oauth_type: OAuthType.Facebook,
    })

    setLoading(true)

    cookieAgree.activate()

    const t = setTimeout(() => {
      clearTimeout(t)
      setLoading(false)
    }, 10000)
  }

  const loginUrl = useMemo(() => getLoginUrl(), [props.redirectUrl])

  return (
    <Modal
      placement={isMobile ? 'center' : 'top'}
      size="md"
      backdrop="blur"
      hideCloseButton={!props.canClose}
      isOpen={props.isOpen}
      onClose={props.canClose ? props.onClose : undefined}
    >
      <ModalContent>
        {() => (
          <>
            <ModalHeader className="flex flex-col gap-1 text-center text-xl lg:text-2xl pt-10">
              Let the magic begin ✨
            </ModalHeader>
            <ModalBody>
              <div className="text-sm lg:text-base">
                To generate your one of a kind photo book we need your
                approval to access
                {' '}
                <b>your name</b>
                {', '}
                <b>profile picture</b>
                {', '}
                <b>email address, and your photos</b>
                {'. '}
                This data will be used according to the
                {' '}
                <a target="_blank" className="underline" href={PRIVACY_LINK} rel="noreferrer">
                  Privacy Policy
                </a>
                {' '}
                and
                {' '}
                <a target="_blank" className="underline" href="/terms-and-conditions">
                  Terms and Conditions
                </a>
                .
                We use cookies for login, checkout and stats according to the
                {' '}
                <a target="_blank" className="underline" href={COOKIE_POLICY} rel="noreferrer">
                  Cookies Policy
                </a>
              </div>

              <Button
                as={Link}
                size={isMobile ? 'sm' : 'md'}
                variant="solid"
                color="primary"
                className="mb-2"
                href={loginUrl}
                isLoading={loading}
                onClick={onLogin}
                startContent={<SvgFacebook size={14} color="#FFF" />}
              >
                Login with Facebook
              </Button>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
