import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { feedback } from '@helpers/feedback'
import { Button } from '@nextui-org/button'
import { useIsMobile } from '@nextui-org/use-is-mobile'

export const FeedbackButton = () => {
  const isMobile = useIsMobile()
  return (
    <Button
      onClick={feedback.open}
      isIconOnly={isMobile}
      variant="flat"
      className="fixed rounded-full right-2 z-40 bottom-20 md:bottom-20"
      startContent={<FontAwesomeIcon icon={faStar} className="text-yellow-500" />}
    >
      {!isMobile ? 'Give feedback' : undefined}
    </Button>
  )
}
