import { memo, SVGProps } from 'react'

import { Props } from './types'

export const SvgFacebook = memo((props: SVGProps<SVGSVGElement> & Props) => (
  <svg
    width={props.size}
    height={props.size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      d="M99.605 50.803C99.605 23.297 77.308 1 49.803 1 22.297 1 0 23.297 0 50.803 0 75.66 18.212 96.263 42.02 100V65.198H29.376V50.803h12.646V39.83c0-12.483 7.435-19.377 18.81-19.377 5.448 0 11.15.973 11.15.973v12.256H65.7c-6.187 0-8.117 3.84-8.117 7.782v9.338h13.812L69.19 65.198H57.584V100c23.81-3.736 42.021-24.34 42.021-49.197Z"
      fill={props.color}
    />
  </svg>
))
