import { User } from '@api/gql/graphql'
import {
  browserTracingIntegration,
  init as initSentry,
  setUser as setSentryUser,
} from '@sentry/react'

const init = () => {
  initSentry({
    environment: import.meta.env.VITE_ENV,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: import.meta.env.VERSION,
    debug: import.meta.env.VITE_SENTRY_DEBUG === 'true',
    beforeSend: () => {
      return null
    },
  })
}

export const setUser = (user: User) => {
  setSentryUser({
    id: user.id,
  })
}

export const sentry = {
  init,
  setUser,
}
