import { useEffect } from 'react'
import image from '@assets/netherlands.jpg'
import { PageName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'

export const ContactsPage = () => {
  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.Contacts,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.Contacts,
    })

    facebookPixel.event('PageView')
  }, [])

  return (
    <div className="page text-xs lg:text-base">
      <img src={image} alt="" className="w-full mb-4 lg:mb-10" />

      <h1 className="text-xl lg:text-5xl font-bold mb-2 lg:mb-5">Contact us</h1>
      <h2 className="text-xs lg:text-xl font-bold">Easy Photo Book</h2>
      <p>KvK000049481169</p>
      <p>Btw-id NL003802856B43</p>
      <p>Orteliusstraat 17-D</p>
      <p>1057 AR Amsterdam, The Netherlands</p>
      <p>
        <a href="mailto:info@easyphotobook.me" className="underline text-primary">
          info@easyphotobook.me
        </a>
      </p>
    </div>
  )
}

export default ContactsPage
