export enum ModalType {
  ADMIN_ORDER_MODAL = 'admin-order-modal',
  LOGIN_MODAL = 'login-modal',
  ALERT_MODAL = 'alert-modal',
  CONFIRM_MODAL = 'confirm-modal',
  SELECT_ALBUM_COVER_MODAL = 'select-album-cover-modal',
  EDIT_ALBUM_METADATA_MODAL = 'edit-album-metadata-modal',
  MOVE_IMAGE_MODAL = 'move-image-modal',
}

export const StaticModalTypes = [ModalType.LOGIN_MODAL]

export type AdminOrderModalProps = {
  orderId: string
}

export type LoginModalProps = {
  redirectUrl?: string
  canClose?: boolean
}

export type AlertModalProps = {
  title: string
  description: string
}

export type ConfirmModalProps = {
  title: string
  message?: string
  onConfirm?: () => void
  onCancel?: () => void
  isDismissable?: boolean
  hideCloseButton?: boolean
}

export type SelectAlbumCoverModalProps = {
  album_id: string
}

export type EditAlbumMetadataModalProps = {
  album_id: string
}

export type MoveImageModalProps = {
  album_id: string
  album_image_id: string
}

export type OpenModalProps =
  ({ type: ModalType.ADMIN_ORDER_MODAL } & AdminOrderModalProps) |
  ({ type: ModalType.LOGIN_MODAL } & LoginModalProps) |
  ({ type: ModalType.ALERT_MODAL } & AlertModalProps) |
  ({ type: ModalType.CONFIRM_MODAL } & ConfirmModalProps) |
  ({ type: ModalType.SELECT_ALBUM_COVER_MODAL } & SelectAlbumCoverModalProps) |
  ({ type: ModalType.EDIT_ALBUM_METADATA_MODAL } & EditAlbumMetadataModalProps) |
  ({ type: ModalType.MOVE_IMAGE_MODAL } & MoveImageModalProps)

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
}
