import { FC, ReactNode } from 'react'
import { clsx } from 'clsx'

type Props = {
  children: ReactNode
  className?: string
}

export const Container: FC<Props> = (props) => {
  return <div className={clsx('container mx-auto max-w-[1000px]', props.className)}>{props.children}</div>
}
