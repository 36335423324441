import { useContext } from 'react'
import { useNavigate } from 'react-router'
import image from '@assets/landing-album.jpg'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { StaticContext } from '@helpers/static-context'

export const Hero = () => {
  const isStatic = useContext(StaticContext)
  const navigate = useNavigate()

  return (
    <>
      <div className="border w-full h-full top-0 left-0 absolute z-20 flex flex-col items-center justify-center">
        <h1 className="text-xl mt-16 lg:mt-0 lg:text-5xl text-white font-bold text-center mb-4 lg:mb-10">
          The photo book you have always
          <br />
          {' '}
          wanted, but never had time to make
        </h1>

        <a
          href={isStatic ? '/create' : '#'}
          className="text-sm px-4 py-3 lg:px-8 lg:py-6 bg-primary text-white font-bold lg:text-2xl rounded-full"
          onClick={() => {
            amplitude.event({ type: AmplitudeEvent.MainClickCreate })

            if (isStatic) {
              return
            }

            navigate('/create')
          }}
        >
          Create photo book
        </a>
      </div>

      <img src={image} alt="" className="w-full h-auto" width={2080} height={1120} />
    </>
  )
}
