import hotToast, { Toaster } from 'react-hot-toast'

import { Opts } from './constants'

const error = (message: string, opts?: Opts) => hotToast(
  message,
  { style: { backgroundColor: '#FFF', color: '#000' }, icon: '❌', ...opts },
)

const success = (message: string, opts?: Opts) => hotToast(
  message,
  { style: { backgroundColor: '#FFF', color: '#000' }, icon: '✅', ...opts },
)

const info = (message: string, opts?: Opts) => hotToast(
  message,
  { style: { backgroundColor: '#FFF', color: '#000' }, icon: 'ℹ️', ...opts },
)

const warning = (message: string, opts?: Opts) => hotToast(
  message,
  { style: { backgroundColor: '#FFF', color: '#000' }, icon: '👀', ...opts },
)

export const toast = {
  error,
  success,
  info,
  warning,
}

export const ToastPlacement = () => <Toaster position="top-center" />
