import { FC } from 'react'
import { User } from '@api/gql/graphql'
import { Avatar } from '@nextui-org/avatar'
import { clsx } from 'clsx'

type Props = {
  profile?: User | null
}

export const ProfileButton: FC<Props> = ({ profile }) => {
  return (
    <>
      <Avatar
        src={profile?.avatar_url ?? undefined}
        className="bg-gray-100 text-gray-300 h-6 w-6 lg:h-8 lg:w-8"
      />

      <div className={clsx(
        'hidden lg:flex',
        profile ? 'text-black' : 'text-gray-500',
      )}
      >
        {profile ? profile?.name : 'You’re not logged in'}
      </div>
    </>
  )
}
