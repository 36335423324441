import reactGa from 'react-ga'
import { User } from '@api/gql/graphql'
import { PageName } from '@constants/analytic'

export enum GaCategory {
  Page = 'page',
  Order = 'order',
}

export enum GaAction {
  PageView = 'page.view',
  OrderClickPay = 'order.click.pay',
  OrderSuccessPay = 'order.success.paid',
}

type GaParams =
    ({
      category: GaCategory.Page,
      action: GaAction.PageView,
    } & { name: PageName }) |
    ({
      category: GaCategory.Order,
      action: GaAction.OrderClickPay,
    } & { value: number }) |
    ({
      category: GaCategory.Order,
      action: GaAction.OrderSuccessPay,
    } & { value: number })

export const setUser = (user: User) => {
  reactGa.set({ userId: user.id })
}

export const init = () => {
  reactGa.initialize(import.meta.env.VITE_GA, {
    titleCase: false,
    testMode: import.meta.env.VITE_ENV !== 'production',
    debug: false,
  })
}

export const event = (params: GaParams) => {
  if (params.category === GaCategory.Page && params.action === GaAction.PageView) {
    reactGa.pageview(params.name)
    return
  }

  reactGa.event({
    category: params.category,
    action: params.action,
    label: params.category,
    value: params.value,
  })
}

export const ga = {
  init,
  event,
  setUser,
}
