import { createContext } from 'react'

import { OpenModalProps } from '../_types'

export type ModalContextState = {
  openModal: (props: OpenModalProps) => void
}

export const Context = createContext<ModalContextState>({
  openModal: () => {},
})
