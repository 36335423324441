import { memo, SVGProps } from 'react'

import { Props } from './types'

export const SvgBookRight = memo((props: SVGProps<SVGSVGElement> & Props) => (
  <svg
    width={props.size}
    height={props.size}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <g clipPath="url(#book-right_svg__a)">
      <path fill="#fff" d="M0 0h100v100H0z" />
      <path
        d="M87.425 14.389c1.213.276 2.299 1.17 2.895 2.363l.553 1.128v27.994c0 19.244-.064 28.27-.234 28.845-.32 1.15-1.767 2.534-2.959 2.853-.532.128-3.491.277-6.6.34-8.302.17-12.879.64-17.796 1.831-4.364 1.043-7.557 2.534-9.835 4.577l-1.107 1 .042-32.527.064-32.529 1.341-.788c3.002-1.745 8.26-3.384 13.242-4.108 3.661-.554 6.216-.767 11.495-.98 5.748-.234 7.92-.234 8.899 0Z"
        fill="#DEDEDE"
      />
      <path
        d="M97.494 23.926c.83.426 1.32.873 1.788 1.639l.639 1.022.064 27.802c.042 20.586 0 28.1-.17 28.952-.341 1.512-1.768 3.023-3.236 3.385-1.405.362-39 .362-38.787.021.276-.468 3.618-1.873 5.62-2.384 4.704-1.214 8.196-1.597 17.669-1.937l7.557-.256 1.426-.66c1.98-.936 3.407-2.32 4.386-4.3l.787-1.597.064-26.142.043-26.141h.51c.278 0 1.023.276 1.64.596Z"
        fill="#DEDEDE"
      />
      <path
        d="M22.112 14.389c11.517.51 19.777 2.299 24.524 5.364l1.022.639V85.32l-1.107-1c-2.81-2.513-7.067-4.216-13.262-5.302-4.173-.702-10.815-1.192-16.797-1.192-2.043 0-3.725-.106-4.257-.255-1.129-.34-2.512-1.724-2.853-2.853-.191-.68-.255-7.344-.255-28.888V17.837l.596-1.128c.425-.83.872-1.32 1.618-1.788 1.022-.618 1.128-.639 3.938-.66 1.596-.021 4.662.042 6.833.128Z"
        fill="#949494"
      />
      <path
        d="m4.699 49.429.064 26.163.68 1.426c.937 1.98 2.321 3.406 4.28 4.364l1.575.788 7.6.256c9.75.34 14.902.957 19.117 2.341 1.681.554 4.002 1.64 4.215 2.001.085.15-5.982.213-18.904.213-21.608 0-20.565.085-22.055-1.618-.596-.68-.915-1.298-1.085-2.065-.17-.787-.213-9.047-.17-28.888l.063-27.78.596-1.001c.639-1.107 1.98-2.023 3.215-2.236l.766-.127.043 26.163Z"
        fill="#949494"
      />
    </g>
    <defs>
      <clipPath id="book-right_svg__a">
        <path fill="#fff" d="M0 0h100v100H0z" />
      </clipPath>
    </defs>
  </svg>
))
