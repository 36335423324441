import cookie from 'js-cookie'

export const cookieAgree = {
  activate: () => {
    cookie.set('cookie-agree', '1', {
      path: '/',
      expires: 1000000,
      secure: !import.meta.env.DEV,
      domain: window.location.hostname,
    })
  },
  get: () => !!cookie.get('cookie-agree'),
}
