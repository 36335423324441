import { useState } from 'react'
import { COOKIE_POLICY } from '@constants/link'
import { cookieAgree } from '@helpers/cookie-agree'
import { tokenStore } from '@helpers/token-store'

export const CookieAlert = () => {
  const [agree, setAgree] = useState<boolean>(cookieAgree.get())
  const [token] = useState(tokenStore.get())

  const isShow = !token && !agree

  return isShow ? (
    <div className="flex items-center justify-center fixed bottom-2 w-full z-40">
      <div className="flex flex-col md:block text-center bg-black/80 text-white rounded-3xl p-3">
        <div className="mb-2 text-sm">
          We use cookies for login, checkout, and analytics. Learn more in our
          {' '}
          <a href={COOKIE_POLICY} className="underline">Privacy Policy</a>
          .
        </div>
        <button
          type="button"
          className="bg-primary px-4 py-1 text-base rounded-md"
          onClick={() => {
            setAgree(true)
            cookieAgree.activate()
          }}
        >
          I
          agree
        </button>
      </div>
    </div>
  ) : <></>
}
