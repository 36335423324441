import { tokenStore } from '@helpers/token-store'
import axios, { AxiosRequestConfig } from 'axios'

// cache reset 111
export class EndpointError extends Error {
  httpStatus: number | null = null
}

export type QueryParams = { [key: string]: number | string }

export type RestEndpointResponse<Content> = {
  content: Content
  message: string
}

export const buildQueryParams = (params?: QueryParams) => {
  if (!params) {
    return ''
  }

  const stringParams = Object.keys(params).map((p) => `${p}=${params[p]}`)
  if (!stringParams.length) {
    return ''
  }

  return `?${stringParams.join('&')}`
}

const getAuthorizationHeader = async (): Promise<object> => {
  const token = tokenStore.get()
  if (!token) {
    return {}
  }

  return {
    Authorization: `Bearer ${token}`,
  }
}

const request = async <Response>(
  config: AxiosRequestConfig,
) => {
  const authorizationHeader = await getAuthorizationHeader()

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...config.headers,
    ...authorizationHeader,
  }

  return axios
    .request<Response>({
    ...config,
    headers,
    validateStatus: () => true,
  })
    .then((response) => {
      if (response.status === 200) {
        if (config.responseType === 'json') {
          return response.data
        }

        return response.data
      }

      const err = new EndpointError((response.data as any)?.message || response.statusText)
      err.httpStatus = response.status

      throw err
    })
}

const get = <Response, Params extends QueryParams>(
  path: string,
  params?: Params,
  config?: AxiosRequestConfig,
) => request<Response>({
    method: 'get',
    url: import.meta.env.VITE_REST_ENDPOINT + path + buildQueryParams(params),
    ...config,
  })

const post = <Response, Body>(
  path: string,
  body?: Body,
  config?: AxiosRequestConfig,
) => request<Response>({
    method: 'post',
    url: import.meta.env.VITE_REST_ENDPOINT + path,
    data: body,
    ...config,
  })

const put = <Response, Body>(
  path: string,
  body?: Body,
  config?: AxiosRequestConfig,
) => request<Response>({
    method: 'put',
    url: import.meta.env.VITE_REST_ENDPOINT + path,
    data: body,
    ...config,
  })

const del = <Response>(
  path: string,
  config?: AxiosRequestConfig,
) => request<Response>({
    method: 'delete',
    url: import.meta.env.VITE_REST_ENDPOINT + path,
    ...config,
  })

export const restEndpoint = {
  get,
  post,
  put,
  del,
}
