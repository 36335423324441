export type EventName = 'PageView' | 'Purchase' | 'AddToCart';

export type EventData = {
  content_ids?: number[] | string[]
  value?: string | number
  currency?: string
  predicted_ltv?: string
  content_category?: string
  content_name?: string
  content_type?: string
  contents?: [{
    id: string
    quantity: number
  }];
  num_items?: string
  search_string?: string
  status?: boolean
  event_id?: string
};

type Options = {
  eventID?: string
}

declare global {
  interface Window {
    fbq: (type: 'init' | 'track', eventName?: string, data?: EventData, options?: Options) => string
  }
}

const minify = (code: string) => {
  return code.replace(/\s+/g, '')
}

const init = () => {
  const facebookPixelElement = document.getElementById('facebook-pixel')
  if (facebookPixelElement) {
    return
  }

  const script = document.createElement('script')
  script.id = 'facebook-pixel'
  const code = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', ${import.meta.env.VITE_FACEBOOK_PIXEL_ID})
  `

  const s = document.createTextNode(minify(code))
  script.appendChild(s)
  document.head.appendChild(script)
}

const event = (eventName: EventName, params?: EventData) => {
  window?.fbq('track', eventName, params, {
    eventID: params?.event_id,
  })
}

export const facebookPixel = {
  init,
  event,
}
