import { useEffect } from 'react'
import { PageName } from '@constants/analytic'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { facebookPixel } from '@helpers/facebook-pixel'
import { ga, GaAction, GaCategory } from '@helpers/ga'

export const MainPage = () => {
  useEffect(() => {
    amplitude.event({
      type: AmplitudeEvent.PageView,
      name: PageName.Main,
    })

    ga.event({
      category: GaCategory.Page,
      action: GaAction.PageView,
      name: PageName.Main,
    })

    facebookPixel.event('PageView')
  }, [])

  return (
    <div className="page">
      <section className="text-lg lg:text-4xl font-bold mb-48">
        You are not going to find a long list of useless benefits and features
        here, only important facts. We create photo books from your Facebook
        photos within a few seconds and zero hassle.
      </section>
      <section className="text-lg lg:text-4xl font-bold mb-48">
        All of our books are hardcover, high-quality glossy pages in a perfect
        square format. The pictures will look like new no matter how old they
        are.
      </section>
      <section className="text-xl lg:text-5xl font-bold mb-48 leading-snug">
        Try it yourself. You’ll be surprised how easy it is.
        {' '}
        <span className="text-primary">Create your own photo book now.</span>
      </section>
    </div>
  )
}

export default MainPage
