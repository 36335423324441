import { FC } from 'react'
import { Button } from '@nextui-org/button'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from '@nextui-org/modal'
import { useIsMobile } from '@nextui-org/use-is-mobile'

import { AlertModalProps, ModalProps } from '../_types'

export const AlertModal: FC<ModalProps & AlertModalProps> = (props) => {
  const isMobile = useIsMobile()

  return (
    <Modal
      placement="center"
      size="md"
      backdrop="blur"
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalContent>
        {(onClose) => (
          <form onSubmit={onClose} className="flex flex-col justify-center">
            <ModalHeader className="flex flex-col gap-1 text-center text-xl lg:text-2xl pt-10">
              {props.title}
            </ModalHeader>
            <ModalBody>
              <div className="text-center mb-4 text-sm lg:text-base">{props.description}</div>

              <Button
                size={isMobile ? 'sm' : 'md'}
                type="button"
                variant="solid"
                color="primary"
                className="mb-2"
                onClick={props.onClose}
              >
                Ok
              </Button>
            </ModalBody>
          </form>
        )}
      </ModalContent>
    </Modal>
  )
}

export default AlertModal
