import { amplitude } from '@helpers/amplitude'

export const PRIVACY_LINK = 'https://www.iubenda.com/privacy-policy/25863337'
export const COOKIE_POLICY = 'https://www.iubenda.com/privacy-policy/25863337/cookie-policy'

export const getLoginUrl = () => {
  const url = new URL(window.location.href)
  url.searchParams.set('amplitude_device_id', amplitude.getDeviceId() ?? '')
  return `${import.meta.env.VITE_REST_ENDPOINT}/oauth/facebook/login?redirect_url=${encodeURIComponent(url.toString())}`
}

export const PEECHO_ORDER_LINK = import.meta.env.VITE_ENV === 'production'
  ? 'https://www.peecho.com/mio-dashboard/orders/'
  : 'https://test.www.peecho.com/mio-dashboard/orders/'

export const ASSET_URL = `${import.meta.env.VITE_REST_ENDPOINT}/asset`

export const getAmplitudeLinkToUser = (userId: string): string => {
  return `https://app.amplitude.com/analytics/EasyPhotoBook/users?search=${userId}&searchType=search`
}
