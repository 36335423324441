import { memo, SVGProps } from 'react'

import { Props } from './types'

export const SvgHeart = memo((props: SVGProps<SVGSVGElement> & Props) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    {...props}
  >
    <path
      d="M50 12.136C55.308 5.366 63.557 1 72.05 1 87.158 1 99 13.918 99 30.4 99 50.624 75.235 74.055 50 99 24.765 73.965 1 50.535 1 30.4 1 13.918 12.842 1 27.95 1 36.443 1 44.692 5.365 50 12.136Z"
      fill="#FF5A5A"
    />
  </svg>
))
