import { useContext } from 'react'

import { Context } from './context'

export const useModal = () => {
  const { openModal } = useContext(Context)

  return {
    openModal,
  }
}
